import React, {Component} from "react"
import Layout from "./../../components/layout";
import SEO from "./../../components/seo";
import WorkshopContainer from "./../../components/workshop-container";
import WorkshopData from "./workshop-data.json";
// import styled from "styled-components";
// import COLORS from "./../../styles/colors";

import Workshop from "./shared/workshop";
import {InnerPage, WorkshopGrid, PageWrapper} from "./shared/default-workshop.styles";

// const FilterWrapper = styled.div`
//     background-color: ${COLORS.primaryColor};
//     color: #fff;
// `;

// const FilterContainer = styled.div`
//     max-width: 1500px;
//     padding: 0 2rem;
//     margin: 0 auto;
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     h1 {
//         font-weight: 300;
//         font-size: 1.5rem;

//         @media screen and (max-width: 1100px) {
//             font-size: 1.4rem;
//         }
//     }

//     @media screen and (max-width: 1100px) {
//         flex-direction: column;
//     }
// `;

// const StateFilters = styled.div`
//     flex: 1;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-evenly;
//     padding: 2rem 1rem;
//     @media screen and (max-width: 1100px) {
//         width: 100%;
//         padding: 1rem 0 2rem;
//     }
// `;

// const StateFilter = styled.div`
//     text-align: center;
//     border: 2px solid #fff;
//     color: ${props => props.activefilter === "true" ? COLORS.primaryColor : "#fff"};
//     padding: 0.5rem 1rem;
//     transition: all 0.3s;
//     border-radius: 5px;
//     background-color: ${props => props.activefilter === "true" ? "#fff" : "transparent"};
//     &:hover {
//         background-color: #fff;
//         color: ${COLORS.primaryColor};
//         cursor: pointer;
//     }
// `;

class HighStakesTestingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: [],
            filter: "all",
            touched: false,
            filters: ["all", "georgia", "florida", "south-carolina", "texas"],
            index: 0
        }
    }

    componentDidMount() {
        // this.timerID = setInterval(
        //     () => {
        //         if (!this.state.touched) {
        //             const index = this.state.index === 3 ? 0 : this.state.index + 1;
        //             this.setState({
        //                 index,
        //                 filter: this.state.filters[index] 
        //             })
        //         }
        //     },
        //     3000
        //   );
    }

    componentWillUnmount() {
        // clearInterval(this.timerID);
    }

    toggleFilter = filter => {
        if (!this.state.touched) {
            clearInterval(this.timerID);
            this.setState({touched: true});
        }
        if (this.state.filter === filter) this.setState({filter: ""})
        else this.setState({filter});
    }


    render() {
        const SocialStudiesWorkshops = WorkshopData.filter(workshop => workshop.highStakesLearning);
        const {filter} = this.state;

        const filteredWorkshops = filter 
            ? SocialStudiesWorkshops.filter(workshop => {
                if(filter === "all") {
                    return true;
                }
                else {
                    return workshop.states && workshop.states.includes(filter)
                }
            })
            : SocialStudiesWorkshops;

        return (
            <Layout>
                <SEO title="High-Stakes Workshops | s3strategies" />
                <WorkshopContainer title="High-Stakes Testing" />
                {/* <FilterWrapper>
                    <FilterContainer>
                        <h1>Aligned To Your State Standards</h1>
                        <StateFilters>
                            <StateFilter activefilter={filter === "all" ? "true" : "false"} onClick={() => this.toggleFilter("all")}>All</StateFilter>
                            <StateFilter activefilter={filter === "georgia" ? "true" : "false"} onClick={() => this.toggleFilter("georgia")}>Georgia</StateFilter>
                            <StateFilter activefilter={filter === "florida" ? "true" : "false"} onClick={() => this.toggleFilter("florida")}>Florida</StateFilter>
                            <StateFilter activefilter={filter === "south-carolina" ? "true" : "false"} onClick={() => this.toggleFilter("south-carolina")}>South Carolina</StateFilter>
                            <StateFilter activefilter={filter === "texas" ? "true" : "false"} onClick={() => this.toggleFilter("texas")}>Texas</StateFilter>
                        </StateFilters>
                    </FilterContainer>
                </FilterWrapper> */}
                <PageWrapper>
                    <InnerPage>
                        <WorkshopGrid>
                            {filteredWorkshops.map((workshop, index) => (
                               <Workshop workshop={workshop} index={index} />
                            ))}
                        </WorkshopGrid>
                        <br />
                        <br />
                    </InnerPage>
                </PageWrapper>
            </Layout>
        )
    }
}
export default HighStakesTestingPage;